<template>
  <div>
    <h3 class="page-title">ھوقۇق باشقۇرۇش</h3>
    <el-divider></el-divider>
    <el-row class="rtl-right">
      <el-button type="primary" plain icon="el-icon-right" @click="back">قايتىش</el-button>
      <el-button type="success" plain icon="el-icon-circle-plus-outline" @click="save">مۇقىملاش</el-button>
      <el-button type="warning"  plain icon="el-icon-refresh-left" @click="resetChecked">ئەسلىگە ئېكىلىش</el-button>
    </el-row>
    <el-row>
      <p class="role-des">{{roleDescription}}</p>
    </el-row>
    <el-row v-loading="loading">
      <el-col :span="23" :offset="1">
        <el-tree :data="permissions" ref="tree" show-checkbox node-key="id" :props="defaultProps" 
          :default-checked-keys="checkedKeys" @check="clickCheck" empty-text="رول تىزىملىكىدىن بىرنى تاللاپ ھوقۇق بېكىتىڭ">
        </el-tree>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  var self;
  export default {
    activated: function() {
      self = this;

      self.roleId = self.$route.query.roleId;
      self.roleDescription = self.$route.query.roleDescription;

      if(self.roleId){
        self.getPermissions();
      }
    },
    data() {
      return {
        roleId:'',
        roleDescription:'',
        permissions: [],
        checkedKeys:[],
        defaultProps: {
          children: 'children',
          label: 'label'
        },
        permissionIds:[],
        loading:false
      };
    },
    methods: {
      back(){
        self.$router.back();
      },
      getPermissions() {
        self.loading = true;
        self.$fetch("admin/roles/"+self.roleId+"/permissions").then(response => {
          if (response.status == 200) {
            self.checkedKeys = response.data.data.permissions;
            self.permissions = response.data.data.lists;
          } else if (response.status == -1000) {
            console.log(response.msg);
          }
          self.loading = false;
        }).catch(err => {
          console.log(err);
          self.loading = false;
        });
      },
      resetChecked() {
        self.$refs.tree.setCheckedKeys([]);
      },
      save(){
        self.loading = true;
        self.$post('admin/roles/'+self.roleId+'/permissions',{
          permissions_id:self.$refs.tree.getCheckedKeys(true)
        }).then((response) => {
          console.log('-------------');
          console.log(response);
          if(response.status == 201){
            self.getPermissions();
            self.$message({
              message: response.data.message,
              type: "success",
              duration: 1200
            });
            //window.history.go(0); //每次设置完权限之后需要刷新一下，以便重新获取菜单以及权限(menus & permissions)
            window.getAllPermissions();
          }else{
            self.$message({
              message: response.data.message,
              type: 'warning'
            });
          }
          self.loading = false;
        }).catch(err => {
          console.log('××××××××××');
          console.log(err);
          self.loading = false;
        });
      },
      clickCheck(a,b){
        // console.log(a);
        console.log(a.id);
      }
    },
    deactivated(){
      self.permissions = [];
    }
  };
</script>

<style>
  .role-des{
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 20px;
    text-align: center;
  }
</style>

